@white: #fff;
@black: #000;
@wbg: #8fb421;
@grey: #a1a1a1;
@lightGrey: rgba(0,0,0,0.08);

@maxWidth: 1400px;

@spacing: 25px;

.archivoNarrow() {
    font-family: 'Archivo Narrow', sans-serif;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 400;
}

@375: ~"only screen and (min-width: 375px)";
@435: ~"only screen and (min-width: 435px)";
@480: ~"only screen and (min-width: 480px)";
@768: ~"only screen and (min-width: 768px)";
@992: ~"only screen and (min-width: 992px)";
@1100: ~"only screen and (min-width: 1100px)";
@1200: ~"only screen and (min-width: 1200px)";
@1450: ~"only screen and (min-width: 1450px)";
@o375: ~"only screen and (min-width: 375px) and (max-width: 768px)";
@o768: ~"only screen and (min-width: 768px) and (max-width: 992px)";
@o992: ~"only screen and (min-width: 992px) and (max-width: 1200px)";