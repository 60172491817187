.footer {
    padding: @spacing;
    background: @wbg;
    color: @white;
    margin-top: (@spacing*2);
    a {
        color: @white;
    }
}
.footer-content {
    grid-row-start: 3;
    grid-row-end: 4;
    max-width: @maxWidth;
    margin: 0 auto;
    @media @435 {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: @spacing;
        grid-template-rows: auto;
        grid-template-areas:
            "adress hours"
            "contact nav";
    }
    @media @768 {
        grid-template-columns: auto auto auto 1fr auto;
        grid-column-gap: @spacing;
        grid-template-rows: auto;
        grid-template-areas:
            "adress contact hours . nav";
    }
}
.footer__adress {
    grid-area: adress;
}
.footer__contact {
    grid-area: contact;
}
.footer__hours {
    grid-area: hours;
}

.footer-nav {
    grid-area: nav;
    @media @768 {
        justify-self: end;
    }
}
.footer-nav__list {
    padding: 0;
    margin: (@spacing/2) 0;
}
.footer-nav__link {
    padding-right: @spacing;
    @media @768 {
        padding: (@spacing/4) 0 (@spacing/4) @spacing;
    }
}
.footer-nav__link--active {
    text-decoration: underline;
}