// MIXINS AND CONSTANTS GO HERE

.box-shadow(@param) {
    -webkit-box-shadow: @param;
       -moz-box-shadow: @param;
        -ms-box-shadow: @param;
         -o-box-shadow: @param;
            box-shadow: @param;
}
.box-sizing(@param) {
    -webkit-box-sizing: @param;
       -moz-box-sizing: @param;
        -ms-box-sizing: @param;
         -o-box-sizing: @param;
            box-sizing: @param;
}
.box-decoration-break(@param) {
    -webkit-box-decoration-break: @param;
       -moz-box-decoration-break: @param;
        -ms-box-decoration-break: @param;
         -o-box-decoration-break: @param;
            box-decoration-break: @param;
}
.transition(@param) {
    -webkit-transition: @param;
       -moz-transition: @param;
        -ms-transition: @param;
         -o-transition: @param;
            transition: @param;
}
.column-count(@c,@g) {
    -webkit-column-count: @c;
       -moz-column-count: @c;
        -ms-column-count: @c;
            column-count: @c;
      -webkit-column-gap: @g;
         -moz-column-gap: @g;
          -ms-column-gap: @g;
              column-gap: @g;
}
.transform(@param) {
    -webkit-transform: @param;
        -ms-transform: @param;
       -moz-transform: @param;
            transform: @param;
}
.flexbox() {
    display: -webkit-flex;
     display: -webkit-box;
     display: -ms-flexbox;
        display: -moz-box;
            display: flex;
}
.flex(@values) {
    -webkit-box-flex: @values;
       -moz-box-flex: @values;
        -webkit-flex: @values;
            -ms-flex: @values;
                flex: @values;
}
.order(@val) {
    -webkit-box-ordinal-group: @val;
       -moz-box-ordinal-group: @val;
               -ms-flex-order: @val;
                -webkit-order: @val;
                        order: @val;
}
.flexwrap(@val) {
    -webkit-flex-wrap: @val;
        -ms-flex-wrap: @val;
            flex-wrap: @val;
}
.flexdir(@val) {
    -webkit-flex-direction: @val;
        -ms-flex-direction: @val;
            flex-direction: @val;
}
.flexflow(@val) {
    -webkit-flex-flow: @val;
            flex-flow: @val;
}
.placeholder(@param) {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: @param;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: @param;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: @param;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: @param;
    }
    &::placeholder {
        color: @param;
    }
}