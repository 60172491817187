.col {
    @media @768 {
        display: grid;
        grid-column-gap: @spacing;
    }
    &.col-100 {
        display: block;
    }
    &.col-50 {
        @media @768 {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
                "headline headline"
                "left right";
        }
    }
    &.col-33 {
        @media @768 {
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas:
                "headline headline headline"
                "left middle right";
        }
    }
}

.h1, .h2, .h3 {
    grid-area: headline;
}
.col__left {
    grid-area: left;
    margin-bottom: @spacing;
}
.col__middle {
    grid-area: middle;
}
.col__right {
    grid-area: right;
    margin-bottom: @spacing;
}