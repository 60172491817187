@import (inline) '../css/normalize-7.0.0.css';
@import (inline) '../css/font-awesome.css';
@import 'modules/_base';
@import 'modules/_font';
@import 'modules/_mediaQuerys';
@import 'modules/_columns';
@import 'modules/_navigation';
@import 'modules/_header';
@import 'modules/_footer';

* {
    .box-sizing(border-box);
}

html {
    height: 100%;
}
body {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    .archivoNarrow();
    overflow-x: hidden;
}
a {
    color: @black;
    text-decoration: none;
    &:hover, &:active {
        text-decoration: underline;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
    }
}
img {
    max-width: 100%;
    height: auto;
    display: block;
}
ul {
    padding-left: 16px;
    margin: 0;
    @media @768 {
        padding-left: 40px;
    }
}

.h1 {
    font-size: 1.2em;
    line-height: 1.2em;
    display: block;
    left: 0;
    right: 0;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    grid-area: headline;
    @media @480 {
        font-size: 1.3em;
    }
    @media @768 {
        position: absolute;
        bottom: 0;
        font-size: 2em;
    }
    @media @992 {
        font-size: 2.5em;
    }
    @media @1450 {
        font-size: 3em;
    }
}
.h2 {
    font-size: 1.3em;
    line-height: 1.2em;
    font-weight: 400;
    @media @435 {
        font-size: 1.4em;
    }
    @media @768 {
        font-size: 1.5em;
    }
    @media @992 {
        font-size: 1.65em;
    }
    @media @1450 {
        font-size: 1.8em;
    }
}

.main {
    margin: 0 auto;
    width: 100%;
    max-width: @maxWidth;
    padding: 0 @spacing;
    @media @1450 {
        padding: 0;
    }
}

.nobreak {
    white-space: nowrap;
}

.flat-img {
    margin: 0 0 @spacing 0;
}

.table {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-row-gap: @spacing;
    grid-template-rows: repeat(auto-fill, auto);
}
.t-desc {
    grid-column: 1;
}
.t-content {
    grid-column: 2;
    margin: 0;
}

.form {
    position: relative;
}
.input {
    width: 100%;
    margin-bottom: (@spacing/2);
    background: @lightGrey;
    color: @grey;
    border: none;
    padding: (@spacing/4);
    .placeholder(@grey);
}
.textarea {
    width: 100%;
    height: 7.2em;
    background: @lightGrey;
    color: @grey;
    border: none;
    padding: (@spacing/4);
    margin-bottom: (@spacing/2);
    resize: unset;
    .placeholder(@grey);
}
.button {
    position: absolute;
    right: 0;
    border: none;
    background: @wbg;
    border-radius: 0;
    color: @white;
    padding: (@spacing/4) @spacing;
    cursor: pointer;
    bottom: -@spacing;
}