/* archivo-narrow-regular - latin */
@font-face {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/archivo-narrow-v24-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/archivo-narrow-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/archivo-narrow-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/archivo-narrow-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('../fonts/archivo-narrow-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/archivo-narrow-v24-latin-regular.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-700 - latin */
@font-face {
    font-family: 'Archivo Narrow';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/archivo-narrow-v24-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/archivo-narrow-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/archivo-narrow-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/archivo-narrow-v24-latin-700.woff') format('woff'), /* Modern Browsers */
        url('../fonts/archivo-narrow-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/archivo-narrow-v24-latin-700.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}
/* archivo-narrow-700italic - latin */
@font-face {
    font-family: 'Archivo Narrow';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/archivo-narrow-v24-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/archivo-narrow-v24-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/archivo-narrow-v24-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/archivo-narrow-v24-latin-700italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/archivo-narrow-v24-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/archivo-narrow-v24-latin-700italic.svg#ArchivoNarrow') format('svg'); /* Legacy iOS */
}