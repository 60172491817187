.header {
    border-bottom: 5px solid @wbg;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "header-img"
        "headline";
    // margin-bottom: (@spacing*2);
    @media @768 {
        margin-bottom: 0;
    }
}
.header-img {
    margin: 0 auto;
    opacity: .24;
    grid-area: header-img;
}
.logo {
    width: 15%;
    position: absolute;
    left: 50%;
    .transform(translateX(-50%));
    top: @spacing;
    @media @375 {
        width: 17%;
    }
    @media @435 {
        width: 19%;
    }
    @media @768 {
        width: 23%;
    }
}