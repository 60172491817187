.nav {
    position: fixed;
    display: block;
    z-index: 9999;
    top: @spacing;
    left: @spacing;
    @media @768 {
        position: relative;
        top: unset;
        left: unset;
        max-width: @maxWidth;
        margin: 0 auto;
        width: 100%;
        padding: 0 (@spacing/2);
        top: 0;
    }
    @media @1450 {
        padding: 0;
    }
}
.nav__button {
    display: inline-block;
    @media @o375 {
        &:hover, &:active, &:focus {
            -webkit-user-select: none;
            -webkit-touch-callout: none;
            .nav__list {
                display: block;
                position: absolute;
            }
            .nav__item {
                display: block;
            }
        }
        
    }
}
.nav__button--mobile {
    @media @768 {
        display: none;
    }
}
.nav__list {
    display: none;
    background: @white;
    padding: (@spacing/2);
    margin: 0;
    @media @768 {
        background: none;
        display: block;
        padding: 0;
        margin: (@spacing/2) 0;
    }
    @media @1450 {
        margin: (@spacing/2) -(@spacing/2);
    }
    &.nav__list--indent {
        @media @768 {
            margin: (@spacing/2) (@spacing/2);
        }
        @media @1450 {
            margin: (@spacing/2) 0;
        }
    }
}
.nav__item {
    list-style: none;
    display: inline-block;
}
.nav__link {
    padding: 2.5px (@spacing/2);
    display: block;
    margin: (@spacing/4);
    @media @768 {
        margin: 0;
    }
}
.nav__link--active {
    background: @wbg;
    color: @white;
}